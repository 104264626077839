const PREFIX = 'api/v1'

const PROTO = 'https'
const DOMAIN = 'fm-service.duckdns.org'
const PORT = '443'

const getUri = (url) => {
  return `/${PREFIX}${url}`
}

export default getUri