<template>
  <div class="mt-4">
    <div class="columns is-2-fullhd">
      <div class="column is-2">
        <aside class="menu">
          <p class="menu-label">
            Навигация
          </p>
          <ul class="menu-list">
            <li>
              <router-link :to="{name: 'a-list-tasks'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/admin/tasks') > -1 }">
                Задачи
              </router-link>
               <ul>
                <li>
                  <router-link :to="{name: 'a-list-tasks'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-add-task'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-list-accounts'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/admin/accounts') > -1 }">
                Договора
              </router-link>
               <ul>
                <li>
                  <router-link :to="{name: 'a-list-accounts'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-add-account'}">
                    Добавить
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-upload-accounts'}">
                    Загрузить
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-list-customers'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/admin/customers') > -1 }">
                Клиенты
              </router-link>
               <ul>
                <li>
                  <router-link :to="{name: 'a-list-customers'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-add-customer'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
          <p class="menu-label">
            Управление
          </p>
          <ul class="menu-list">
            <li>
              <router-link :to="{name: 'a-list-users'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/users') > -1 }">
                Администраторы
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-list-users'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-add-user'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-list-staff'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/staff') > -1 }">
                Cотрудники
              </router-link>
               <ul>
                <li>
                  <router-link :to="{name: 'a-list-staff'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-add-staff'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-list-cities'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/admin/cities') > -1 }">
                Города
              </router-link>
               <ul>
                <li>
                  <router-link :to="{name: 'a-list-cities'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-add-city'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-list-states'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/admin/states') > -1 }">
                Статусы
              </router-link>
               <ul>
                <li>
                  <router-link :to="{name: 'a-list-states'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-add-state'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-list-categories'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/admin/categories') > -1 }">
                Категории
              </router-link>
               <ul>
                <li>
                  <router-link :to="{name: 'a-list-categories'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-add-category'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-list-subcategories'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/admin/subcategories') > -1 }">
                Работы
              </router-link>
               <ul>
                <li>
                  <router-link :to="{name: 'a-list-subcategories'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-add-subcategory'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </aside>
      </div>
      <div class="column  is-10">
        <div class="">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  
export default {
  name: 'LayoutAdmin',
  components: {
    //
  },
  props: {
    //
  },
  data: function () {
    return {
      isLoading: false
    }
  },
  computed: {
    //
  },
  beforeMount: function(){
    //
  },
  beforeDestroy: function(){
    //
  },
  created: function() {
    //
  },
  mounted: function(){
    //
  },
  methods: {
    BS_UPDATE () {
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {
          //
        }
      })
    },
  }
}
</script>

<style scoped>
</style>
<style>
.menu {
  text-align: left;
  height: 100hv;
}
</style>
