<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <router-view/>
      </div>
    </div>
  </section>
</template>

<script>

import { EventBus } from '@/utils'
// import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'LayoutAuth',
  components: {
    //
  },
  props: {
    //
  },
  data: function () {
    return {
      //
    }
  },
  created: function() {
    //
  },
  computed: {
    //
  },
  beforeMount: function(){
    //
  },
  beforeDestroy: function(){
    //
  },
  mounted: function(){
    //
  },
  methods: {
    //
  }
}
</script>

<style scoped>
</style>
<style>
</style>
