import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

export default new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/',
      name: 'index',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Task/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/users/add',
      name: 'a-add-user',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/User/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/users/:id/password',
      name: 'a-password-user',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/User/Passwd.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/users/:id',
      name: 'a-view-user',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/User/View.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/users/',
      name: 'a-list-users',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/User/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/staff/add',
      name: 'a-add-staff',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Staff/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/staff/:id',
      name: 'a-view-staff',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Staff/View.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/staff/',
      name: 'a-list-staff',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Staff/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/categories/add',
      name: 'a-add-category',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Category/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/categories/:id',
      name: 'a-view-category',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Category/View.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/categories/',
      name: 'a-list-categories',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Category/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/subcategories/add',
      name: 'a-add-subcategory',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/SubCategory/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/subcategories/:id',
      name: 'a-view-subcategory',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/SubCategory/View.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/subcategories/',
      name: 'a-list-subcategories',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/SubCategory/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/states/add',
      name: 'a-add-state',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/State/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/states/:id',
      name: 'a-view-state',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/State/View.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/states/',
      name: 'a-list-states',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/State/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/accounts/add',
      name: 'a-add-account',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Account/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/accounts/upload',
      name: 'a-upload-accounts',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Account/Upload.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/accounts/:id',
      name: 'a-view-account',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Account/View.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/accounts/',
      name: 'a-list-accounts',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Account/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/customers/add',
      name: 'a-add-customer',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Customer/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/customers/:id',
      name: 'a-view-customer',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Customer/View.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/customers/',
      name: 'a-list-customers',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Customer/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/cities/add',
      name: 'a-add-city',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/City/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/cities/:id',
      name: 'a-view-city',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/City/View.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/cities/',
      name: 'a-list-cities',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/City/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/tasks/add',
      name: 'a-add-task',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Task/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/tasks/:id',
      name: 'a-view-task',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Task/View.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/tasks/',
      name: 'a-list-tasks',
      meta: { layout: "ui-admin" },
      component: () => import('@/components/Task/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/404', 
      name: '404', 
      meta: { layout: "ui-404" },
      component: () => import('@/components/Page404.vue'), 
    },
    { 
      path: '/login', 
      name: 'login', 
      meta: { layout: "ui-auth" },
      component: () => import('@/components/PageLogin.vue'), 
    },
    { 
      path: '/signup', 
      name: 'signup', 
      meta: { layout: "ui-auth" },
      component: () => import('@/components/PageLogin.vue'), 
    },
    {
      path: '/logout',
      name: 'logout',
      meta: { layout: "ui-logout"},
      beforeEnter (to, from, next) {
        store.dispatch("logout")
        next('/')
      }
    },
    { 
      path: '*', 
      redirect: '/404' 
    }
  ]
})
