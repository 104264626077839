import Vue from 'vue'  
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist/dist/umd'

const vuexPersist = new VuexPersist({
  key: 'adder-ui',
  storage: localStorage
})

import { loginUser } from '@/api/auth'
import { isValidJwt, EventBus } from '@/utils'

Vue.use(Vuex)

const state = {  
  user: {},
  jwt_user: ''
}

const actions = {
  "logout" (context){
    context.commit('logOut')
  },
  // Auth User
  "user.login" (context, userData) {
    context.commit('setUserData', { userData })
    return loginUser(userData)
      .then(response => {
        context.commit('setJwtUserToken', { data: response.data })
      })
      .catch(error => {
        //pass
      })
  },
}

const mutations = {  
  setUserData (state, payload) {
    if(payload.userData){
      state.user = {
        username: payload.userData.username
      }
    }
    else{
      state.user = {}
    }
  },
  setJwtUserToken (state, payload) {
    state.jwt_user = payload.data.access_token
  },
  logOut (state) {
    state.user = {}
    state.jwt_user = ''
  },
}

const getters = {  
  isAuthenticatedUser (state) {
    if(state.jwt_user == undefined){
      return false
    }
    return isValidJwt(state.jwt_user)
  },
  getUser(state){
    return state.user
  },
}

export default new Vuex.Store({
  getters: getters,
  mutations: mutations,
  actions: actions,
  state: state,
  plugins: [vuexPersist.plugin]
})
