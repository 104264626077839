const axios = require('axios')
import getUri from "./index"


export function loginUser (userData) {
  let headers = {
    headers: {
      'accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }
  const params = new URLSearchParams()

  params.append('username', userData["username"])
  params.append('password', userData["password"])

  return axios.post(getUri('/auth/token'), params, headers)
}
