import Vue from 'vue'
export const EventBus = new Vue()

export function isValidJwt (jwt) {
  if (!jwt || jwt.split('.').length < 3) {
    return false
  }
  const data = JSON.parse(atob(jwt.split('.')[1]))
  const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
  const now = new Date()
  return now < exp
}

export const LIMITS = [
  5, 10, 15, 20, 25, 50, 100, 200
]

export const YES_NO = [
  true, false
]