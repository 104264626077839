import Vue from 'vue'
import App from './App.vue'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import "./vee-validate";

import store from "@/store"

import VueRouter from './router.js'

// Icons
import { library } from '@fortawesome/fontawesome-svg-core';
// Internal icons
import { faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle, faPen, faTrash,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faSortDown, faAt, faKey, faAddressCard, faPlay, faBolt,
    faPhone, faBuilding, faMapMarkerAlt, faServer, faSadTear, faCogs, faFileSignature, faUserFriends, faRoute, faVectorSquare,
    faUserShield, faList, faPlus, faUsers, faCopy, faLayerGroup, faHome, faFileImport, faCalendarAlt, faSearchMinus, faSearchPlus, faHandPaper, faHandPointer,
    faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faExclamation, faUser, faShoppingCart, faMapMarkedAlt, faMap, faCalendar, faTimesCircle, faToggleOff, faToggleOn, faLock } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle, faExclamation, faUser, faBolt,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faShoppingCart, faMapMarkedAlt, faMap, faSortDown, faAt, faKey, faRoute, faVectorSquare,
    faAddressCard, faPhone, faBuilding,faMapMarkerAlt, faServer, faSadTear, faCogs, faFileSignature, faUserFriends, faSearchMinus, faSearchPlus,
    faUserShield, faList, faPlus, faUsers, faCopy, faLayerGroup, faHome, faFileImport, faCalendarAlt, faPen, faTrash, faHandPaper, faHandPointer,
    faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faPlay, faCalendar, faTimesCircle, faToggleOff, faToggleOn, faLock);

Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
  customIconPacks: {
    fas: {
      sizes: {
        default: "1x",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x"
      },
      iconPrefix: ""
    }
  }
});

Vue.config.productionTip = false;


// layouts
import LayoutAdmin from "./layouts/LayoutAdmin.vue";
import LayoutAuth from "./layouts/LayoutAuth.vue";
import Layout404 from "./layouts/Layout404.vue";
Vue.component("lay-ui-admin", LayoutAdmin);
Vue.component("lay-ui-auth", LayoutAuth);
Vue.component("lay-ui-404", Layout404);

// MomentJS
import moment from 'moment'
import 'moment/locale/ru'
Vue.prototype.$moment = moment
Vue.prototype.$moment.locale('ru')

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  store: store,
  router: VueRouter,
}).$mount('#app')

// export const eventBus = createApp(App)

// createApp(App).mount('#app')