import { required, confirmed, length, email, digits, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "Это поле обязательно"
});

extend("email", {
  ...email,
  message: "Это поле должно быть email"
});

extend("digits", {
  ...digits,
  message: "Это поле должно быть числом"
});

extend("numeric", {
  ...numeric,
  message: "Это поле должно быть числом"
});

// extend("confirmed", {
//   ...confirmed,
//   message: "This field confirmation does not match"
// });

// extend("length", {
//   ...length,
//   message: "This field must have 2 options"
// });
